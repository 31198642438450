// routes
import { paths } from 'src/routes/paths';
// config
// components
import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

export const navConfig = [
  {
    title: 'Anasayfa',
    icon: <Iconify icon="iconoir:home" width={24} />,
    path: paths.bayi,
  },
  {
    title: 'Ödemeler',
    icon: <Iconify icon="iconoir:credit-card" width={24} />,
    path: paths.account.payment.list,
  },
  {
    title: 'Siparişler',
    icon: <Iconify icon="iconoir:page" width={24} />,
    path: paths.account.order.list,
   }
];
