/* eslint-disable eqeqeq */
import React from 'react';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';
import MenuItem from '@mui/material/MenuItem';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { useTheme } from '@mui/material/styles';
import { Alert, Button, Chip, ListItemIcon } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { useLocation } from 'react-router';
import { useRouter } from 'src/routes/hooks';
import { AnimateAvatar } from 'src/components/animate/animate-avatar';
import { paths } from 'src/routes/paths';
import { useAuthContext } from 'src/auth/hooks';
import { useSnackbar } from 'src/components/snackbar';
import { usePopover } from 'src/components/custom-popover';
import { AccountButton } from 'src/components/account-button';
import Scrollbar from 'src/components/scrollbar';
import Iconify from 'src/components/iconify';
import { SignOutButton } from 'src/components/sign-out-button';
import { useCheckout } from 'src/store/slices/checkout/checkout.hooks';
import { showOnlyForRoles } from 'src/utils/permissions';
import { useOptions } from '../../store/slices/options/hooks.slice';

// ----------------------------------------------------------------------
export type AccountDrawerProps = IconButtonProps & {
  data?: {
    label: string;
    href: string;
    icon?: React.ReactNode;
    info?: React.ReactNode;
  }[];
};


// ----------------------------------------------------------------------

export default function AccountDrawer({ data = [], sx }: AccountDrawerProps) {
  const router = useRouter();
  const theme = useTheme();
  const options = useOptions();
  const { logout, user ,selectedCompany} = useAuthContext();
  const { enqueueSnackbar } = useSnackbar();
  const popover = usePopover();
  const { totalItems } = useCheckout();
  const location = useLocation();
  

  const isPanelPath = location.pathname.split('/')[1];
  const handleLogout = async () => {
    try {
      await logout();
      popover.onClose();
      router.replace('/auth/jwt/login?returnTo=%2F');
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  const handleClickItem = (path: string) => {
    popover.onClose();
    router.push(path);
  };

  const handleCompanyChange = () => {
    popover.onClose();
    options.setOptions({ open: true });
  };
  const renderAvatar = (
    <AnimateAvatar
      width={96}
      slotProps={{
        avatar: { src: user?.image ?? '', alt: `${user?.first_name} ${user?.last_name}` },
        overlay: {
          border: 2,
          spacing: 3,
          color: `linear-gradient(135deg, ${alpha(theme.palette.primary.dark, 0)} 25%, ${theme.palette.primary.main} 100%)`,
        },
      }}
    >
      {user?.displayName?.charAt(0).toUpperCase()}
    </AnimateAvatar>
  );
  return (
    <>
      <AccountButton
        open={Boolean(popover.open)}
        onClick={popover.onOpen}
        photoURL={user?.photoURL}
        displayName={user?.displayName}
      />

      <Drawer
        open={Boolean(popover.open)}
        onClose={popover.onClose}
        anchor="right"
        slotProps={{ backdrop: { invisible: true } }}
        PaperProps={{ sx: { width: 320 } }}
      >
        <IconButton
          onClick={popover.onClose}
          sx={{ top: 12, left: 12, zIndex: 9, position: 'absolute' }}
        >
          <Iconify icon="mingcute:close-line" />
        </IconButton>

        <Scrollbar>
          <Stack alignItems="center" sx={{ pt: 8 }}>
            {renderAvatar}

            <Typography variant="subtitle1" noWrap sx={{ mt: 2 }}>
              {user?.first_name ?? 'User'} {user?.last_name ?? 'Name'}
            </Typography>

            <Typography variant="body2" sx={{ color: 'text.secondary', mt: 0.5 }} noWrap>
              {user?.email}
            </Typography>
          </Stack>
          

          <Stack sx={{ p: 1 }} spacing={2}>
          {user && (
            <>
            <MenuItem >
            <Alert  severity="info" sx={{ width: '100%' }}
            action={
              (user?.Role.type == 'admin' ||user?.Role.type == 'seller' || user?.Company.length > 1) && (
              <Button color="inherit" size="small" onClick={handleCompanyChange}>
                Değiştir
              </Button>
            ) }
            >
              {selectedCompany ? selectedCompany?.name : user?.Company[0]?.name }
            </Alert>
            </MenuItem>

            {user.Role.type === 'admin' && (
              isPanelPath !== 'panel' ? (
                <MenuItem onClick={() => handleClickItem(paths.dashboard.root)}>
                <ListItemIcon>
                <Iconify icon="material-symbols:admin-panel-settings-outline" />
                </ListItemIcon>
                <Typography variant="body2">Admin Panel</Typography>
              </MenuItem>
              ) : (
                
                <MenuItem onClick={() => handleClickItem(paths.bayi)}>
                 <ListItemIcon>
                  <Iconify icon="mdi:home" />
                 </ListItemIcon>
                  <Typography variant="body2">Anasayfa</Typography>
               </MenuItem>
              )
            )}

            {user.Role.type === 'companyuser' && (
              <MenuItem onClick={() => handleClickItem(paths.bayi)}>
                <ListItemIcon>
                  <Iconify icon="mdi:home" />
                </ListItemIcon>
                <Typography variant="body2">Anasayfa</Typography>
              </MenuItem>
            )}
            </>
          )}
            
          <MenuItem onClick={() => handleClickItem(paths.product.checkout)}>
            <ListItemIcon>
              <Iconify icon="solar:cart-3-bold" />
            </ListItemIcon>
            <Box sx={{ flexGrow: .2 }}>Sepet</Box>
            <Chip label={totalItems} variant="outlined" color="error"/>
            {/* <Badge badgeContent={totalItems} color="primary" /> */}
          </MenuItem>
          <MenuItem onClick={() => handleClickItem(paths.account.order.list)}>
            <ListItemIcon>
              <Iconify icon="iconoir:page" />
            </ListItemIcon>
            Siparişler
          </MenuItem>
          <MenuItem onClick={() => handleClickItem(paths.account.payment.list)}>
            <ListItemIcon>
              <Iconify icon="iconoir:credit-card" />
            </ListItemIcon>
            Ödemeler
          </MenuItem>
          {user &&  (
            showOnlyForRoles(
              <MenuItem onClick={() => handleClickItem(paths.account.statement.list)}>
              <ListItemIcon>
                <Iconify icon="la:file-invoice" />
              </ListItemIcon>
              Hesap Hareketleri
            </MenuItem>
              ,'price:read')
          )}
          <MenuItem onClick={() => handleClickItem(paths.account.profile)}>
            <ListItemIcon>
              <Iconify icon="clarity:administrator-line" />
            </ListItemIcon>
            Kullanıcı Ayarları
          </MenuItem>
        </Stack>
        </Scrollbar>
        <Box sx={{ p: 2.5 }}>
          <SignOutButton onClick={handleLogout} />
        </Box>
      </Drawer>
    </>
  );
}
