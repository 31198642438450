import { useCallback, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Badge from '@mui/material/Badge';
import { Link } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { Tooltip } from '@mui/material';
import Iconify from 'src/components/iconify';
import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { useResponsive } from 'src/hooks/use-responsive';
import { bgBlur } from 'src/theme/css';
import Logo from 'src/components/logo';
import { HEADER } from '../profile/nav/config-layout';
import { navConfig } from './config-navigation';
import NavDesktop from './nav/desktop';
import { HeaderShadow, AccountDrawer } from '../_common';
import { useDebounce } from '../../hooks/use-debounce';
import Search from '../../components/header/Search';
import { useSearchProducts } from '../../api/product';
import { useCheckout } from '../../store/slices/checkout/checkout.hooks';
import { MegaMenuItem } from '../../components/mega-menu/mega-menu-desktop-horizon';
import { generateQueryOp } from '../../graphql/generated';

export default function Header() {
  const theme = useTheme();
  const { totalItems } = useCheckout();
  const mdUp = useResponsive('up', 'md');
  const offsetTop = useOffSetTop(HEADER.H_DESKTOP);
  const offsetTopForSearch = useOffSetTop(HEADER.H_DESKTOP - 80);
  const [searchQuery, setSearchQuery] = useState('');
  const debouncedQuery = useDebounce(searchQuery);
  const { searchResults, searchLoading } = useSearchProducts(debouncedQuery);
  const handleSearch = useCallback((inputValue: string) => {
    setSearchQuery(inputValue);
  }, []);
  const [megaMenuItems, setMegaMenuItems] = useState<any>([]);

  const { query, variables } = generateQueryOp({
    brands: {
      __args: {
        take: 5,
        where: {
          active: {
            equals: true,
          },
        },
      },
      name: true,
      slug: true,
      Categories: {
        __args: {
          take: 15,
          where: {
            active: {
              equals: true,
            },
          },
        },
        name: true,
        slug: true,
      },
    },
  });

  const { data } = useQuery(gql(query), { variables });

  useEffect(() => {
    if (data?.brands) {
      setMegaMenuItems(
        data?.brands
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((brand: any) => ({
          subheader: brand.name,
          items: brand.Categories
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((category: any) => ({
            title: category.name,
            path: `/product/list?category=${category.name}`,
            key: category.id, // Benzersiz bir key ekleyin, örneğin category.id
          })),
          key: brand.id, // Benzersiz bir key ekleyin, örneğin brand.id
        }))
      );
    }
  }, [data]);

  return (
    <AppBar>
      <Toolbar
        disableGutters
        sx={{
          height: {
            xs: HEADER.H_MOBILE,
            md: HEADER.H_DESKTOP,
          },
          transition: theme.transitions.create(['height'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          }),
          ...(offsetTop && {
            ...bgBlur({
              color: theme.palette.background.default,
            }),
            height: {
              md: HEADER.H_DESKTOP_OFFSET,
            },
          }),
        }}
      >
        <Container sx={{ height: 1, display: 'flex', alignItems: 'center' }}>
          <Logo />
          <Box sx={{ flexGrow: 1 }} />
          {mdUp && (
            <Search
              query={debouncedQuery}
              results={searchResults}
              loading={searchLoading}
              onSearch={handleSearch}
            />
          )}
          <Box sx={{ flexGrow: 1 }} />
          {mdUp && <NavDesktop offsetTop={offsetTop} data={navConfig} />}
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            sx={{ display: { xs: 'none', md: 'flex' }, ml: 1.5, mr: 1.5 }}
          >
            <MegaMenuItem
              parent={{
                title: '',
                path: '/categories',
                icon: <Iconify icon="ri:apps-line" width={24} />,
                children: megaMenuItems,
                more: { title: 'Tümünü gör', path: '/categories' },
              }}
            />
          </Stack>
          <Tooltip title="Sepet">
            <IconButton
              component={Link}
              to="/cart"
              sx={{
                color: 'text.primary',
                '&:hover': { bgcolor: 'transparent' },
                mr: 1.5,
              }}
            >
              <Badge badgeContent={totalItems} color="primary">
                <Iconify icon="solar:cart-3-bold" width={24} />
              </Badge>
            </IconButton>
          </Tooltip>
          <AccountDrawer />
        </Container>
      </Toolbar>
      {offsetTop && <HeaderShadow />}
      {!mdUp && !offsetTopForSearch && (
        <Container sx={{ marginTop: 1 }}>
          <Search
            query={debouncedQuery}
            results={searchResults}
            loading={searchLoading}
            onSearch={handleSearch}
          />
        </Container>
      )}
    </AppBar>
  );
}
