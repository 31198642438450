// @mui
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
// hooks
import Link from '@mui/material/Link';
import { Grid } from '@mui/material';
import { useResponsive } from 'src/hooks/use-responsive';
import Logo from '../../components/logo';
import { IMAGES } from 'src/theme/image';
// components

// ----------------------------------------------------------------------

type Props = {
  image?: string;
  children: React.ReactNode;
};

export default function AuthModernLayout({ children, image }: Props) {
  const upMd = useResponsive('up', 'md');

  const renderContent = (
    <Stack
      sx={{
        marginTop: { xs: 8, md: 0 },
        marginBottom: { xs: 8, md: 0 },
        width: 1,
        mx: 'auto',
        maxWidth: 480,
        px: { xs: 2, md: 8 },
      }}
    >
      <Stack direction="row" alignItems="center" justifyContent="center" spacing={0.5}>
        <Logo
          sx={{
            mt: { xs: 2, md: 4 },
            mb: { xs: 2, md: 4 },
          }}
        />
      </Stack>

      <Card
        sx={{
          paddingTop: { xs: 5, md: 2 },
          py: { xs: 5, md: 4 },
          px: { xs: 3, md: 0 },
          boxShadow: { md: 'none' },
          overflow: { md: 'unset' },
          bgcolor: { md: 'background.default' },
        }}
      >
        {children}
      </Card>

      <Box
        alignItems="center"
        sx={{
          paddingTop: { xs: 5, md: 5 },
          py: { xs: 2, md: 2 },
          px: { xs: 3, md: 0 },
          boxShadow: { md: 'none' },
          overflow: { md: 'unset' },
          bgcolor: { md: 'background.default' },
        }}
      >
        <Grid>
          <Link
            href=""
            variant="body2"
            color="inherit"
            sx={{ alignSelf: 'flex-end' }}
          >
            Gizlilik Politikası
          </Link>

          <span style={{ margin: '0 5px' }}>|</span>

          <Link
            href=""
            variant="body2"
            color="inherit"
            sx={{ alignSelf: 'flex-end' }}
          >
            Hizmet Şartları
          </Link>

          <span style={{ margin: '0 5px' }}>|</span>

          <Link
            href=""
            variant="body2"
            color="inherit"
            sx={{ alignSelf: 'flex-end' }}
          >
            İletişim
          </Link>
        </Grid>

        <Stack direction="row" alignItems="center" spacing={4} marginTop={2}>
          <Box
            component="img"
            alt="bufilogo"
            src={image || '/assets/footer/bufiFooterLogo.png'}
            sx={{
              top: 16,
              left: 16,
              objectFit: 'cover',
              width: 'calc(30% - 32px)',
              height: 'calc(30% - 32px)',
            }}
          />
          <Box
            component="img"
            alt="appstore"
            src={image || '/assets/footer/iosV2.svg'}
            sx={{
              top: 16,
              left: 16,
              objectFit: 'cover',
              width: 'calc(40% - 32px)',
              height: 'calc(40% - 32px)',
            }}
          />
          <Box
            component="img"
            alt="playstore"
            src={image || '/assets/footer/googleV2.svg'}
            sx={{
              top: 16,
              left: 16,
              objectFit: 'cover',
              width: 'calc(40% - 32px)',
              height: 'calc(40% - 32px)',
            }}
          />
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={2}
          marginTop={2}
        >
          <Box
            component="img"
            alt="ssl"
            src={image || '/assets/footer/ssl-1.svg'}
            sx={{
              top: 16,
              left: 16,
              objectFit: 'cover',
              width: 'calc(30% - 32px)',
              height: 'calc(30% - 32px)',
            }}
          />
          <Box
            component="img"
            alt="3dsecure"
            src={image || '/assets/footer/3dsecure.svg'}
            sx={{
              top: 16,
              left: 16,
              objectFit: 'cover',
              width: 'calc(30% - 32px)',
              height: 'calc(30% - 32px)',
            }}
          />
          <Box
            component="img"
            alt="appstore"
            src={image || '/assets/footer/Visa.svg'}
            sx={{
              top: 16,
              left: 16,
              objectFit: 'cover',
              width: 'calc(25% - 32px)',
              height: 'calc(25% - 32px)',
            }}
          />
          <Box
            component="img"
            alt="appstore"
            src={image || '/assets/footer/MasterCard.svg'}
            sx={{
              top: 16,
              left: 16,
              objectFit: 'cover',
              width: 'calc(20% - 32px)',
              height: 'calc(20% - 32px)',
            }}
          />
          <Box
            component="img"
            alt="troy"
            src={image || '/assets/footer/logo-dark-2x.png'}
            sx={{
              top: 16,
              left: 16,
              objectFit: 'cover',
              width: 'calc(20% - 32px)',
              height: 'calc(20% - 32px)',
            }}
          />
        </Stack>
      </Box>
    </Stack>
  );

  const renderSection = (
    <Stack flexGrow={1} sx={{ position: 'relative' }}>
      <Box
        component="img"
        alt="auth"
        src={IMAGES.OVERLAY}
        sx={{
          top: 16,
          left: 16,
          objectFit: 'cover',
          position: 'absolute',
          width: 'calc(100% - 32px)',
          height: 'calc(100% - 32px)',
        }}
      />
    </Stack>
  );

  return (
    <Stack
      component="main"
      direction="row"
      sx={{
        minHeight: '100vh',
        position: 'relative',
        '&:before': {
          width: 1,
          height: 1,
          zIndex: -1,
          content: "''",
          position: 'absolute',
          backgroundSize: 'cover',
          opacity: { xs: 0.24, md: 0 },
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          backgroundImage: 'url(/assets/background/auth.jpg)',
        },
      }}
    >
      {upMd && renderSection}
      {renderContent}
    </Stack>
  );
}
